import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 4 cm de diàmetre, primer ovoide, després obert fins acabar pla, amb un mamelló central ample marró rosat a rogenc, dissociat cap a fora en petites esquames del mateix color o més fosques. El marge és blanquinós i té una part lliure d’esquames. Les nombroses làmines amb lamel·les són de color blanc i estan separades del peu. Les espores són blanques en massa, d’el·líptiques a ovoides i de 5-7 x 3-4 micres. El peu és cilíndric, més gruixut a la base, blanquinós i cobert per davall la zona anular de petites esquames cotonoses del mateix color que les del capell. Pot presentar anell però és molt fugaç.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      